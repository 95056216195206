<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_list border flex_box">
                  <p>알림 허용</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      :checked="isNotification"
                      v-model="isNotification"
                      @change="fnChangeStatus"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="box_list border flex_box">
                  <p>마케팅 정보 동의 제공</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      :checked="isMarketing"
                      v-model="isMarketing"
                      @change="fnChangeStatus"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="box_list border flex_box" @click="fnPage(1)">
                  <p>서비스 이용 약관</p>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(2)">
                  <p>개인정보처리방침</p>
                  <div class="icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con_btn">
          <div class="btn_wrap two_type2">
            <button class="text_btn" @click="fnSignOut">
              로그아웃
            </button>
            <button class="text_btn" @click="fnQuit">
              회원탈퇴
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CONFIRM from '@/components/common/modal/alert/confirm/index'

const useSettingState = () => {
  const { proxy } = getCurrentInstance()
  const store = useStore()

  const userData = computed(() => store.getters['user/getData'])

  const state = reactive({
    isMarketing: userData.value.isMarketing,
    isNotification: userData.value.isNotification
  })

  const fnChangeStatus = async () => {
    const res = await proxy.$UserSvc.updateUserPushStatus({
      isMarketing: state.isMarketing,
      isNotification: state.isNotification
    })
    if (res.code === 1) {
      await proxy.$SignSvc.postMapChangeToken()
    }
  }

  const fnSignOut = async () => {
    const payload = {}
    payload.component = CONFIRM
    payload.layout = {
      title: '알림',
      contents: '로그아웃 하시겠습니까?'
    }
    payload.data = {}
    payload.callBackDone = async () => {
      await store.dispatch('user/signOut')
    }
    payload.callBackCancel = proxy => {
      proxy.$emit('close')
    }
    await store.dispatch('layout/pushModalAlertComponent', payload)
  }
  return { fnSignOut, userData, fnChangeStatus, ...toRefs(state) }
}

export default {
  name: 'menu-setting-index',
  description: '설정 페이지',
  setup() {
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const store = useStore()

    const fnPage = page => {
      if (page === 1) {
        router.push({
          path: `/menu/setting/terms`
        })
      } else {
        router.push({
          path: `/menu/setting/privacy`
        })
      }
    }

    const fnQuit = async () => {
      const payload = {}
      payload.component = CONFIRM
      payload.layout = {
        title: '알림',
        contents: '회원탈퇴하시겠습니까?'
      }
      payload.data = {}
      payload.callBackDone = async () => {
        const res = await proxy.$SignSvc.deleteUser()
        if (res.code === 1) {
          alert('탈퇴되었습니다.')
          await store.dispatch('user/signOut')
        }
      }
      payload.callBackCancel = proxy => {
        proxy.$emit('close')
      }
      await store.dispatch('layout/pushModalAlertComponent', payload)
    }

    return {
      ...useSettingState(),
      fnPage,
      fnQuit
    }
  }
}
</script>

<style scoped></style>
